import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './styles/ProfilePage.css';

const ProfilePage = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [memberStatus, setMemberStatus] = useState('');
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showLeaveConfirmation, setShowLeaveConfirmation] = useState(false);
  const [token, setToken] = useState('');
  const [redirectAfterConfirm, setRedirectAfterConfirm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch('http://https://lighthearted-moxie-82edfd.netlify.app/api/auth/token');
        if (response.ok) {
          const data = await response.json();
          setToken(data.token);
          fetchUserAccount(data.token);
        } else {
          throw new Error('Failed to fetch token');
        }
      } catch (error) {
        console.error('Error fetching token:', error);
      }
    };

    fetchToken();
  }, []);

  const fetchUserAccount = async (token) => {
    try {
      const response = await fetch('http://https://lighthearted-moxie-82edfd.netlify.app/api/userAccount', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ userID: 'yourUserID' }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user account');
      }

      const userData = await response.json();
      setUsername(userData.username);
      setEmail(userData.email);
      setMemberStatus(userData.memberStatus);
    } catch (error) {
      console.error('Error fetching user account:', error);
    }
  };

  const handleLogout = () => {
    setShowLogoutConfirmation(true);
  };

  const handleLeaveGroup = () => {
    setShowLeaveConfirmation(true);
  };

  const confirmLogout = (confirmed) => {
    if (confirmed) {
      window.location.href = '/';
    }
    setShowLogoutConfirmation(false);
  };

  const confirmLeaveGroup = async (confirmed) => {
    if (confirmed) {
      try {
        const response = await fetch('http://https://lighthearted-moxie-82edfd.netlify.app/api/leaveParty', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ userID: 'yourUserID', partyID: 'yourPartyID' }),
        });

        if (response.ok) {
          console.log('Left group successfully');
          setRedirectAfterConfirm('/join');
        } else {
          const result = await response.json();
          console.error('Error leaving group:', result.error);
        }
      } catch (error) {
        console.error('Error leaving group:', error);
      }
    }
    setShowLeaveConfirmation(false);
  };

  // Redirect after confirmation
  useEffect(() => {
    if (redirectAfterConfirm) {
      window.location.href = redirectAfterConfirm;
    }
  }, [redirectAfterConfirm]);

  return (
    <div className="profile-page-container">
      <h1 className="profile-header">Profile</h1>
      <div className="profile-content">
        <div className="profile-details">
          <div className="profile-image">
            <div className="profile-placeholder">Profile</div>
          </div>
          <div className="profile-info">
            <div className="username">{username}</div>
            <div className="email">{email}</div>
            <div className="status">Group Status: {memberStatus}</div>
          </div>
        </div>
        <div className="button-space">
          <button className="profile-button" onClick={handleLeaveGroup}>
            Leave Group
          </button>
          <button className="profile-button" onClick={() => navigate('/changepassword')}>
            Change Password
          </button>
          <button className="profile-button" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>

      {showLogoutConfirmation && (
        <div className="popup-background">
          <div className="popup-container">
            <p>Are you sure you want to logout?</p>
            <div className="confirmation-buttons">
              <button onClick={() => confirmLogout(true)}>Yes</button>
              <button onClick={() => confirmLogout(false)}>No</button>
            </div>
          </div>
        </div>
      )}

      {showLeaveConfirmation && (
        <div className="popup-background">
          <div className="popup-container">
            <p>Are you sure you want to leave the group?</p>
            <div className="confirmation-buttons">
              <button onClick={() => confirmLeaveGroup(true)}>Yes</button>
              <button onClick={() => confirmLeaveGroup(false)}>No</button>
            </div>
          </div>
        </div>
      )}

      <div className="navigation-bar">
        <div className="nav-item">
          <Link to="/search">Search</Link>
        </div>
        <div className="nav-item">
          <Link to="/vote">Vote</Link>
        </div>
        <div className="nav-item">
          <Link to="/home">Home</Link>
        </div>
        <div className="nav-item current-page">
          <Link to="/profile">Profile</Link>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
