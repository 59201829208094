import React from 'react';
import { Link } from 'react-router-dom';
import './styles/HomePage.css';

const HomePage = () => {
  const groupName = "Welcome to 'Large Project'"; 

  const users = [
    { id: 1, username: 'Alice', email: 'alice@example.com' },
    { id: 2, username: 'Bob', email: 'bob@example.com' },
    { id: 3, username: 'Charlie', email: 'charlie@example.com' },
    { id: 4, username: 'David', email: 'david@example.com' },
  ];

  return (
    <div className="home-page-container">
      <div className="group-name">
        {groupName}
      </div>
      <div className="content">
        <div className="group-members">
          <h2>Group Members</h2>
          {users.map(user => (
            <div key={user.id} className="user-box">
              <div className="username">{user.username}</div>
              <div className="email">{user.email}</div>
            </div>
          ))}
        </div>
        <div className="group-picks">
          <h2>Group's Top Picks</h2>
          {/* Placeholder for Group's Top Picks content */}
        </div>
      </div>
      <div className="navigation-bar">
        <div className="nav-item">
          <Link to="/search">Search</Link>
        </div>
        <div className="nav-item">
          <Link to="/vote">Vote</Link>
        </div>
        <div className="nav-item current-page">
          <Link to="/home">Home</Link>
        </div>
        <div className="nav-item">
          <Link to="/profile">Profile</Link>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
