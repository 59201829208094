import React, { useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import './styles/VotePage.css';


const VotePage = () => {
    const navigate = useNavigate(); 

  // Mock movies data for testing
  const [movies, setMovies] = useState([
    { id: 1, title: 'Movie 1', votes: 5, watchedStatus: false },
    { id: 2, title: 'Movie 2', votes: 3, watchedStatus: false },
    { id: 3, title: 'Movie 3', votes: 7, watchedStatus: false },
    { id: 4, title: 'Movie 1', votes: 5, watchedStatus: false },
    { id: 5, title: 'Movie 2', votes: 3, watchedStatus: false },
    { id: 6, title: 'Movie 3', votes: 7, watchedStatus: false },
    { id: 7, title: 'Movie 1', votes: 5, watchedStatus: false },
    { id: 8, title: 'Movie 2', votes: 3, watchedStatus: false },
    { id: 9, title: 'Movie 3', votes: 7, watchedStatus: false },
    { id: 10, title: 'Movie 1', votes: 5, watchedStatus: false },
    { id: 11, title: 'Movie 2', votes: 3, watchedStatus: false },
    { id: 12, title: 'Movie 3', votes: 7, watchedStatus: false },
  ]);

  const handleUpvote = async (movieId) => {
    try {
      const response = await fetch('http://https://lighthearted-moxie-82edfd.netlify.app/api/poll/upvoteMovie', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          partyID: '', 
          movieID: movieId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to upvote movie');
      }

      const data = await response.json();
      const updatedMovies = movies.map((movie) =>
        movie.id === movieId ? { ...movie, votes: data.votes } : movie
      );
      setMovies(updatedMovies);
    } catch (error) {
      console.error('Error upvoting movie:', error);
    }
  };

  const handleMarkWatched = (movieId) => {
    // Simulated function, replace with actual functionality
    console.log(`Marking movie ${movieId} as watched`);
  };

  return (
    <div className="vote-page-container">
      <h1 className="vote-header">Vote</h1>
      <div className="voter-head">
        <h2>Pick which movie you want the group to see!</h2>
      </div>
      <div className="space-div"></div> 
      <div className="movies-list">
        {movies.map((movie) => (
          <div key={movie.id} className="movie-box">
            <div className="movie-title">{movie.title}</div>
            <div className="movie-actions">
              <button
                className="vote-button left-button"
                onClick={() => {
                  handleUpvote(movie.id);
                  navigate('/poll');
                }}
              >
                Upvote
              </button>
              <button
                className="vote-button right-button"
                onClick={() => {
                  handleMarkWatched(movie.id);
                  window.location.href = '/watched'; 
                }}
              >
                Mark as Watched
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="navigation-bar">
        <div className="nav-item">
          <Link to="/search">Search</Link>
        </div>
        <div className="nav-item">
          <Link to="/vote">Vote</Link>
        </div>
        <div className="nav-item">
          <Link to="/home">Home</Link>
        </div>
        <div className="nav-item current-page">
          <Link to="/profile">Profile</Link>
        </div>
      </div>
    </div>
  );
};

export default VotePage;
